<template>
  <div>
    <div class="checkout_container">
      <div class="checkout_container_header">
        <img
          src="@/assets/icons/back.svg"
          :class="[
            localDisplayLang === 'ara'
              ? 'checkout_container_header_back_rtl'
              : 'checkout_container_header_back',
          ]"
          @click="backBtn()"
        />
        <p class="checkout_container_header_title">{{ $t("Credit Card") }}</p>
      </div>
      <form
        @submit.prevent="paynow"
        id="payment-form"
        method="POST"
        action="https://merchant.com/charge-card"
      >
        <div class="form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
        </div>
        <div class="one-liner" v-if="!loader">
          <label for="card-number">{{ $t("Card number") }}</label>
          <div class="input-container card-number">
            <div class="icon-container">
              <img
                id="icon-card-number"
                src="@/assets/icons/card.svg"
                alt="PAN"
              />
            </div>
            <div
              class="card-number-frame"
              :class="[
                localDisplayLang === 'ara'
                  ? 'card-number-frame-ara'
                  : 'card-number-frame',
              ]"
            ></div>
            <div class="icon-container payment-method">
              <img id="logo-payment-method" />
            </div>
            <div class="icon-container">
              <img id="icon-card-number-error" src="@/assets/icons/error.svg" />
            </div>
          </div>

          <div class="date-and-code">
            <div>
              <label for="expiry-date">{{ $t("Expiry date") }}</label>
              <div
                class="input-container"
                :class="
                  localDisplayLang === 'ara' ? 'rtl-expiry-date' : 'expiry-date'
                "
              >
                <div class="icon-container">
                  <img
                    id="icon-expiry-date"
                    src="@/assets/icons/exp-date.svg"
                    alt="Expiry date"
                  />
                </div>
                <div
                  class="expiry-date-frame"
                  :class="[
                    localDisplayLang === 'ara'
                      ? 'expiry-date-frame-ara'
                      : 'expiry-date-frame',
                  ]"
                ></div>
                <div class="icon-container">
                  <img
                    id="icon-expiry-date-error"
                    src="@/assets/icons/error.svg"
                  />
                </div>
              </div>
            </div>

            <div>
              <label for="cvv">{{ $t("Security code") }}</label>
              <div class="input-container cvv">
                <div class="icon-container">
                  <img id="icon-cvv" src="@/assets/icons/cvv.svg" alt="CVV" />
                </div>
                <div
                  class="cvv-frame"
                  :class="[
                    localDisplayLang === 'ara' ? 'cvv-frame-ara' : 'cvv-frame',
                  ]"
                ></div>
                <div class="icon-container">
                  <img id="icon-cvv-error" src="@/assets/icons/error.svg" />
                </div>
              </div>
            </div>
          </div>

          <!-- add submit button -->
          <button class="button-primary primary-net5-button">
            <span class="btn-txt-color"> {{ $t("SUBSCRIBE NOW") }} </span>
          </button>

          <Loading class="form_loader" v-if="formLoader"></Loading>
        </div>
        <div v-else>
          <Loading></Loading>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import Utility from "@/mixins/Utility.js";
export default {
  props: {
    checkoutDetail: {
      type: Object,
    },
    paymentDetail: {
      type: Object,
    },
    finalPromoAmounts: {
      type: Object,
    },
    paymentPrecheckData: {
      type: Object,
    },
  },
  data() {
    return {
      formError: null,
      localDisplayLang: null,
      referenceData: null,
      formLoader: false,
      finalCost: null,
      frameLoader: true,
      promoCode: null,
      loader: false,
			planDiscountCheckout: null,
			subscriberDetails: null,
			currentUserCountry: null
    };
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },
  computed: {
    ...mapGetters(["getRtl", "appConfig"]),
	},
  created() {
    console.log("checkoutDetail in card checkout", this.checkoutDetail);
    console.log("paymentDetail in card checkout", this.paymentDetail);
    console.log(
      "paymentPrecheckData in card checkout",
      this.paymentPrecheckData
    );

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("paymentconfirmation-response", (response) => {
      if (response.reason) {
        this.formLoader = false;
        this.formError = response.reason;
      } else {
        if (response.redirect_url) {
          window.open(response.redirect_url, "_self");
        }
        this.formLoader = true;
      }
    });

    // this.paymentInit();
	//this.planDiscountCheckout = this.appConfig.planDiscountCheckout;
		this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")) ? JSON.parse(localStorage.getItem("subscriberDetails")) : null;
    console.log("subscriberDetails", this.subscriberDetails);
    let subscribedUserCountry = this.subscriberDetails ? this.subscriberDetails.data?.subscribercountry : null;
    console.log("77777", subscribedUserCountry);
    this.currentUserCountry = localStorage.getItem("currentUserCountry")
        ? JSON.parse(localStorage.getItem("currentUserCountry"))
        : null;
	  this.planDiscountCheckout = this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] ? this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] : this.appConfig.planDiscount["DEFAULT"];

    setTimeout(() => {
      this.initializeFrames();
    }, 100);
  },
  methods: {
    ...mapActions(["paymentInitiation", "paymentConfirmation"]),

    paymentInit() {
      console.log("what is the payment data ============", this.paymentDetail);
		let payload;
			// if(this.paymentDetail.detail.planid === "2NJKC6IT"){
			//    payload = {
      //   gatewayid: this.checkoutDetail.gatewayid,
      //   params: {
      //     amount: this.planDiscountCheckout.offerPrice,
      //     currency: this.paymentDetail.detail.currency,
      //     devicetype: "WEB",
      //     transactionpurpose: this.paymentDetail.transactionPurpose,
      //     transactionmode: "CC",
      //     availabilityid: this.paymentDetail.detail.availabilityset[0],
      //     planid: this.paymentDetail.detail.planid,
      //   },
      // };
			// }else {
			   payload = {
        gatewayid: this.checkoutDetail.gatewayid,
        params: {
          amount: this.finalPromoAmounts
            ? this.finalPromoAmounts.totalFinalCost
            : this.paymentPrecheckData.finalamount
            ? this.paymentPrecheckData.finalamount
            : this.paymentPrecheckData.amount,
          currency: this.paymentDetail.detail.currency,
          devicetype: "WEB",
          transactionpurpose: this.paymentDetail.transactionPurpose,
          transactionmode: "CC",
          availabilityid: this.paymentDetail.detail.availabilityset[0],
          planid: this.paymentDetail.detail.planid,
        },
      };
			//}

      if (this.finalPromoAmounts && this.finalPromoAmounts.couponCode) {
        payload.params.couponid = this.finalPromoAmounts.couponCode;
      }

      this.loader = true;
      this.paymentInitiation(payload)
        .then((response) => {
          if (!response.reason) {
            this.loader = false;
            // setTimeout(() => {
            //   this.initializeFrames();
            // }, 100);
            this.referenceData = response.data;

            console.log("Reference Data", this.referenceData);

            let payload = {
              gatewayid: this.checkoutDetail.gatewayid,
              referencedata: this.referenceData,
              checkoutToken: this.checkoutToken,
            };

            console.log("checkoutCallback", payload);
            this.checkoutCallback(payload);

            console.log(
              "what is the reference data================",
              this.referenceData
            );

            const browser = this.getPlatformType();

            const theSource = localStorage.getItem("payment_source");

            let paymentDetailsForGA = {
              free_trial: this.getFreeTrial(),
              payment_mode: this.getPaymentMode(
                this.referenceData.referencedata.gwprovider
              ),
              plan_id: this.paymentDetail.detail.planid,
              plan_name: this.paymentDetail.detail.planname,
              payment_id: this.referenceData.referencedata.paymentid,
              source: theSource,
              object_id: "",
              object_name: "",
              platform: this.getPlatform(),
              platform_type: browser.browser,
            };

            // Google analytics Event
            this.subscriptionStarted(paymentDetailsForGA);

            // Facebook analytics event
            this.faceBookInitateCheckoutEvent(paymentDetailsForGA);
          } else {
            this.formError = response.reason;
          }
        })
        .catch((error) => console.log(error));
    },

    initializeFrames() {
      let self = this;

      Frames.init({
        publicKey: self.checkoutDetail.gwcredential.ApiPublicKey,
        localization: {
          cardNumberPlaceholder: "Card Number",
          expiryMonthPlaceholder: "MM",
          expiryYearPlaceholder: "YY",
          cvvPlaceholder: "CVV",
        },
        style: {
          base: {
            color: "white",
          },
          valid: {
            color: "green",
          },
          invalid: {
            color: "red",
          },
        },
      });

      Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, function (event) {
        console.log("Card tokenization completed", event.token);
        self.checkoutToken = event.token;

        if (self.checkoutToken) {
          self.paymentInit();
        }

        // let payload = {
        //   gatewayid: self.checkoutDetail.gatewayid,
        //   referencedata: self.referenceData,
        //   checkoutToken: self.checkoutToken,
        // };

        // console.log("checkoutCallback", payload);
        // self.checkoutCallback(payload);
      });

      Frames.addEventHandler(
        Frames.Events.CARD_TOKENIZATION_FAILED,
        function () {
          self.onCardTokenizationFailed();
        }
      );

      // let form = document.getElementById("payment-form");

      // form.addEventListener("submit", function(event) {
      //   event.preventDefault();
      //   Frames.submitCard();
      // });
    },

    checkoutCallback(response) {
      let currentDomain = window.location.origin;
      let referencedata = response.referencedata;
      referencedata.referencedata.chargeid = response.checkoutToken;
      referencedata.referencedata.return_url =
        currentDomain +
        "/profile" +
        "?" +
        "planid=" +
        response.referencedata.referencedata.planid +
        "&" +
        "referenceid=" +
        response.referencedata.referenceid;

      let payload = {
        gatewayid: response.gatewayid,
        params: {
          referencedata: referencedata,
        },
      };

      eventBus.$emit("paymentConfirmation", payload);
    },

    onCardTokenizationFailed(error) {
      console.log("CARD_TOKENIZATION_FAILED: %o", error);
      Frames.enableSubmitForm();
    },

    generateLogos() {
      var logos = {};
      logos["card-number"] = {
        src: "card",
        alt: "card number logo",
      };
      logos["expiry-date"] = {
        src: "exp-date",
        alt: "expiry date logo",
      };
      logos["cvv"] = {
        src: "cvv",
        alt: "cvv logo",
      };
      return logos;
    },

    paynow() {
      if (!Frames.isCardValid()) {
        this.formError = "Not a valid card";
      } else {
        this.formLoader = true;

        Frames.submitCard();

        // this.paymentInit();
      }
    },
    backBtn() {
      this.$emit("closeCheckout");
    },
  },
  beforeDestroy() {
    eventBus.$off("paymentconfirmation-response");
  },
  components: {
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
  },
  mixins: [Utility, googleAnalytics, facebookPixelAnalytics],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.checkout_container {
  // padding: 4rem 2rem 3rem;
  //   padding: 2.4rem;
  &_header {
    display: flex;
    &_back {
      margin-right: 5px;
      cursor: pointer;
    }
    &_back_rtl {
      transform: rotate(180deg);
      margin-left: 5px;
      cursor: pointer;
    }
    &_title {
      font-family: $font-regular;
      color: #fff;
      margin: 20px 0;
    }
  }
}
.card_height {
  height: 45px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 0.6rem;
}
.flex_container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}
button {
  margin-top: 20px;
  width: 100%;
  padding: 0.6rem;
}
.form_loader {
  margin-top: 20px;
}
.payment_success {
  font-family: $font-regular;
  text-align: center;
  color: #fff;
  margin: 20px 0;
}
.subscription-success-card {
  border: 1px solid #32363e;
  border-radius: 10px;
  background-color: #191b20;
  position: relative;
  padding: 0.6rem;
  text-align: left;
  .tick {
    position: absolute;
    top: -5px;
    right: -5px;
  }
  .plan-title {
    color: #f8fafd;
    font-family: $font-regular;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
  }
  .plan-type {
    color: #70787f;
    font-family: $font-regular;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 27px;
  }
  .plan-interval {
    color: #4d7bff;
    font-family: $font-regular;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }
}

//frames css styles
label {
  display: block;
  height: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}
.date-and-code {
  display: flex;
  margin-bottom: 8px;
}
.date-and-code > div:nth-child(1) {
  width: 55.715%;
}
.date-and-code > div:nth-child(2) {
  width: 45.719%;
}
.input-container {
  position: relative;
  display: flex;
  height: 40px;
}
.icon-container:last-child {
  right: 0;
}
.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}
.input-container.expiry-date {
  margin-right: 8px;
}

.input-container.rtl-expiry-date {
  margin-left: 8px;
}

#icon-expiry-date {
  width: 20px;
}

#icon-cvv {
  width: 20px;
}

.card-number-frame,
.expiry-date-frame,
.cvv-frame {
  flex: 1 1 auto;
  padding-left: 40px;
}
.card-number-frame-ara,
.expiry-date-frame-ara,
.cvv-frame-ara {
  flex: 1 1 auto;
  padding-left: 10px;
}

div + button {
  margin-top: 8px;
}
.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}
.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  opacity: 1;
  border: solid 1px rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}
.frame--activated.frame--focus {
  border: solid 1px rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}
</style>
